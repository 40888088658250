import {
    EnvironmentProviders,
    InjectionToken,
    makeEnvironmentProviders,
} from '@angular/core';
import {
    ErrorPageInfoRegistry,
    ErrorPageInfoRegistryConfig,
} from '../models/error-page-info.model';

export const ERROR_PAGE_INFO_REGISTRY =
    new InjectionToken<ErrorPageInfoRegistry>('ERROR_PAGE_INFO_REGISTRY', {
        factory: () => new ErrorPageInfoRegistry(),
    });

export function provideErrorPageInfoRegistry(
    config: Partial<ErrorPageInfoRegistryConfig>,
): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: ERROR_PAGE_INFO_REGISTRY,
            useValue: new ErrorPageInfoRegistry(config),
        },
    ]);
}
